<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-row mx-10">
          <div class="d-flex flex-column">
            <h3
              class="font-weight-bold blue-grey--text font text-uppercase font-weight-medium font-md"
            >
              All Members
            </h3>
            <v-btn :to="{ name: 'admin.customers.new' }" color="primary mt-2">
              <span class="font font-sm">Add New Member</span>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12">
        <div class="d-flex flex-row flex-grow-1 justify-end mx-10">
          <v-text-field
            solo
            rounded
            placeholder="Search through members using first name or last name ..."
            flat
            append-icon="search"
            v-model="search"
            hide-details
            class="box-shadow-light font font-sm"
            style="min-width: 400px"
          >
          </v-text-field>
        </div>
      </v-col>
      <v-col cols="12" sm="12" lg="12" md="12" xl="12">
        <v-data-table
          :headers="headers"
          :items="allMembers"
          :loading="pageLoading"
          :server-items-length="paginate.total"
          :options.sync="options"
          class="font box-shadow-light font-weight-medium text-capitalize mx-10"
          loading-text="Loading... Please wait"
          :footer-props="{
            itemsPerPageOptions: [30, 40, 50],
          }"
        >
          <template #item.avatar="{item}">
            <v-avatar color="primary" size="40" class="ma-1 font">
              <span class="white--text subtitle-1 ">{{
                item.firstName
                  ? item.firstName
                  : item.lastName | firstLetterFunction
              }}</span>
            </v-avatar>
          </template>
          <template #item.name="{item}">
            <span class=" font font-sm"
              >{{ item.firstName }} {{ item.lastName }}</span
            >
          </template>
          <template #item.code="{item}">
            <v-btn small text color="primary">
              <span class=" font font-sm">{{ item.code }}</span>
            </v-btn>
          </template>
          <template #item.address="{item}">
            <v-btn small text color="primary">
              <span class=" font font-sm text-capitalize">{{
                item.address1 ? item.address1 : 'No address available'
              }}</span>
            </v-btn>
          </template>
          <template #item.phoneNo="{item}">
            <v-btn small text color="primary">
              <span class=" font font-sm">{{
                item.phoneNo ? item.phoneNo : item.mobileAlert
              }}</span>
            </v-btn>
          </template>
          <template #item.actions="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :to="{
                    name: 'admin.customers.new',
                    query: {
                      id: item._id,
                      action: 'edit',
                    },
                  }"
                  v-on="on"
                  icon
                  fab
                  small
                >
                  <i class="material-icons-outlined font-md">edit</i>
                </v-btn>
              </template>
              <span class=" font font-sm">Edit Member Details</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :loading="isLoading"
                  @click="$store.dispatch('members/delete', item._id)"
                  icon
                  fab
                  small
                >
                  <i class="material-icons-outlined font-md">delete</i>
                </v-btn>
              </template>
              <span class=" font font-sm">Delete Member Details</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
import { debounce } from 'lodash';
import { loadComponent } from '@/utils/helpers';
import { mapGetters } from 'vuex';
import SnackBarMixins from '../../../../mixins/SnackBarMIxins';
export default {
  name: 'AllStaffs',
  data() {
    return {
      options: {},
      search: '',
    };
  },
  components: {
    SnackBar: loadComponent(`component/SnackBar`),
  },

  computed: {
    ...mapGetters({
      allMembers: 'members/getAllMembers',
      pageLoading: 'getIsPageLoading',
      isLoading: 'getIsLoading',
      paginate: 'members/getPagination',
    }),
    form() {
      return {
        name: this.name,
        phone: this.phone,
        accountNumber: this.accountNumber,
      };
    },
    staffList() {
      return this.allStaff.filter(searchTerm => {
        return (
          searchTerm.name.toLowerCase().indexOf(this.search.toLowerCase()) !==
          -1
        );
      });
    },

    headers() {
      return [
        {
          text: '',
          value: 'avatar',
          filterable: false,
          sortable: false,
        },
        {
          text: 'Full Name',
          value: 'name',
          filterable: true,
          sortable: false,
        },
        {
          text: 'Account Number',
          value: 'code',
          filterable: false,
          sortable: false,
        },
        { text: 'Phone', value: 'phoneNo', filterable: false, sortable: false },
        {
          text: 'Address',
          value: 'address',
          filterable: false,
          sortable: false,
        },

        {
          text: 'Actions',
          value: 'actions',
        },
      ];
    },
  },
  filters: {
    status(value) {
      return !value ? 'Active' : 'Inactive';
    },
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    },
  },
  mixins: [SnackBarMixins],
  watch: {
    search: debounce(function(value) {
      if (value === '') {
        this.$store.dispatch('members/list', '?page=1&size=30');
        return;
      }
      const query = `?searchTerm=${value}&page=1&size=30`;
      this.$store.dispatch('members/searchList', query);
    }, 500),
    options: {
      handler(value) {
        const { itemsPerPage, page } = value;
        const query = `?page=${page}&size=${itemsPerPage}`;
        this.$store.dispatch('members/list', query);
      },
      deep: true,
    },
  },
  methods: {},
  created() {
    const query = `?page=1&size=30`;
    this.$store.dispatch('members/list', query);
  },
};
</script>

<style scoped>
.createNew {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  alignt-content: stretch;
  margin-right: 25px;
}

.box {
  height: auto;
  width: auto;
  padding: 5%;
}
</style>
